import type { TupleToUnion } from 'type-fest'

const themeBaseBorderColorNames = [
  'extra-muted',
  'muted',
  'medium',
  'bright',
  'extra-bright',
  'accent',
  'positive',
  'negative',
  'caution',
  'info',
  'transparent',
  'notice',
] as const

const themeBorderColorNames = [
  'dashed-rounded-primary-blue',
  ...themeBaseBorderColorNames,
] as const
type ThemeBorderColor = TupleToUnion<typeof themeBorderColorNames>

const themeRingColorNames = themeBaseBorderColorNames
type ThemeRingColor = TupleToUnion<typeof themeRingColorNames>

const themeDivideColorNames = themeBaseBorderColorNames
type ThemeDivideColor = TupleToUnion<typeof themeDivideColorNames>

const themeHoverBorderColorNames = [
  'cta',
  'secondary',
  'extra-muted',
  'muted',
] as const
type ThemeHoverBorderColor = TupleToUnion<typeof themeHoverBorderColorNames>

const themeFocusBorderColorNames = [
  'muted',
  'caution',
  'positive',
  'negative',
] as const
type ThemeFocusBorderColor = TupleToUnion<typeof themeFocusBorderColorNames>

const themeActiveBorderColorNames = ['cta', 'secondary'] as const
type ThemeActiveBorderColor = TupleToUnion<typeof themeActiveBorderColorNames>

const themeDisabledBorderColorNames = ['cta', 'secondary', 'muted'] as const
type ThemeDisabledBorderColor = TupleToUnion<
  typeof themeDisabledBorderColorNames
>

const themeReadonlyBorderColorNames = ['muted'] as const
type ThemeReadonlyBorderColor = TupleToUnion<
  typeof themeReadonlyBorderColorNames
>

const themeBorderColorMap: Record<ThemeBorderColor, string> = {
  'dashed-rounded-primary-blue': 'bg-primary-blue-rounded-dashed-border',
  'extra-muted': 'border-white/5',
  muted: 'border-white/10',
  medium: 'border-white/30',
  bright: 'border-white/50',
  'extra-bright': 'border-white',
  accent: 'border-primary-blue/30',
  negative: 'border-primary-red',
  caution: 'border-primary-yellow',
  positive: 'border-primary-green',
  info: 'border-primary-blue',
  transparent: 'border-transparent',
  notice: 'border-primary-purple',
}

const themeRingColorMap: Record<ThemeRingColor, string> = {
  'extra-muted': 'ring-white/5',
  muted: 'ring-white/10',
  medium: 'ring-white/30',
  bright: 'ring-white/50',
  'extra-bright': 'ring-white',
  accent: 'ring-primary-blue/30',
  negative: 'ring-primary-red',
  caution: 'ring-primary-yellow',
  positive: 'ring-primary-green',
  info: 'ring-primary-blue',
  transparent: 'ring-transparent',
  notice: 'ring-notice',
}

const themeDivideColorMap: Record<ThemeRingColor, string> = {
  'extra-muted': 'divide-white/5',
  muted: 'divide-white/10',
  medium: 'divide-white/30',
  bright: 'divide-white/50',
  'extra-bright': 'divide-white',
  accent: 'divide-primary-blue/30',
  negative: 'divide-primary-red',
  caution: 'divide-primary-yellow',
  positive: 'divide-primary-green',
  info: 'divide-primary-blue',
  transparent: 'divide-transparent',
  notice: 'divide-notice',
}

const themeHoverBorderColorMap: Record<ThemeHoverBorderColor, string> = {
  cta: 'hover:border-white',
  secondary: 'hover:border-primary-blue/30',
  'extra-muted': 'hover:border-white/10',
  muted: 'hover:border-white/50',
}

const themeHoverRingColorMap: Record<ThemeHoverBorderColor, string> = {
  cta: 'hover:ring-white',
  secondary: 'hover:ring-primary-blue/30',
  'extra-muted': 'hover:ring-white/10',
  muted: 'hover:ring-white/50',
}

const themeFocusBorderColorMap: Record<ThemeFocusBorderColor, string> = {
  muted: 'focus:border-primary-blue',
  negative: 'focus:border-primary-red',
  caution: 'focus:border-primary-yellow',
  positive: 'focus:border-primary-green',
}

const themeFocusRingColorMap: Record<ThemeFocusBorderColor, string> = {
  muted: 'focus:ring-primary-blue',
  negative: 'focus:ring-primary-red',
  caution: 'focus:ring-primary-yellow',
  positive: 'focus:ring-primary-green',
}

const themeFocusWithinBorderColorMap: Record<ThemeFocusBorderColor, string> = {
  muted: 'focus-within:border-primary-blue',
  negative: 'focus-within:border-primary-red',
  caution: 'focus-within:border-primary-yellow',
  positive: 'focus-within:border-primary-green',
}

const themeFocusWithinRingColorMap: Record<ThemeFocusBorderColor, string> = {
  muted: 'focus-within:ring-primary-blue',
  negative: 'focus-within:ring-primary-red',
  caution: 'focus-within:ring-primary-yellow',
  positive: 'focus-within:ring-primary-green',
}

const themeActiveBorderColorMap: Record<ThemeActiveBorderColor, string> = {
  cta: 'active:border-white/5',
  secondary: 'active:border-white/5',
}

const themeDisabledBorderColorMap: Record<ThemeDisabledBorderColor, string> = {
  cta: 'disabled:border-white/5',
  secondary: 'disabled:border-white/5',
  muted: 'disabled:border-black/30',
}

const themeDisabledRingColorMap: Record<ThemeDisabledBorderColor, string> = {
  cta: 'disabled:ring-white/5',
  secondary: 'disabled:ring-white/5',
  muted: 'disabled:ring-black/30',
}

const themeReadonlyBorderColorMap: Record<ThemeReadonlyBorderColor, string> = {
  muted: 'aria-readonly:border-black/5',
}

const themeReadonlyRingColorMap: Record<ThemeReadonlyBorderColor, string> = {
  muted: 'aria-readonly:ring-black/5',
}

export {
  themeActiveBorderColorMap,
  themeBorderColorMap,
  themeBorderColorNames,
  themeDisabledBorderColorMap,
  themeDisabledRingColorMap,
  themeReadonlyBorderColorMap,
  themeReadonlyRingColorMap,
  themeDivideColorMap,
  themeHoverBorderColorMap,
  themeFocusBorderColorMap,
  themeFocusWithinBorderColorMap,
  themeRingColorMap,
  themeHoverRingColorMap,
  themeFocusRingColorMap,
  themeFocusWithinRingColorMap,
  type ThemeActiveBorderColor,
  type ThemeBorderColor,
  type ThemeDisabledBorderColor,
  type ThemeDivideColor,
  type ThemeHoverBorderColor,
  type ThemeFocusBorderColor,
  type ThemeRingColor,
}
